import React, { useContext } from 'react';
import { LanguageContext } from '../../store/LanguageContext';
import image1 from '../../assets/01.png'
import image2 from '../../assets/02.png'
import image3 from '../../assets/03.png'
import image4 from '../../assets/04.png'
import image5 from '../../assets/05.png'
import image6 from '../../assets/06.png'
import image7 from '../../assets/07.png'

const FeatureGrid = () => {
    const { language } = useContext(LanguageContext);

    const content = {
        en: {
            features: [
                {
                    title: 'COACHES',
                    description: 'experienced and successful business leaders from the USA',
                    icon: image1,
                },
                {
                    title: 'TARGET AUDIENCE',
                    description: 'business owners and executives',
                    icon: image2,
                },
                {
                    title: 'VISITS TO COMPANIES',
                    description: 'individual counseling in your company by the course trainers',
                    icon: image3,
                },
                {
                    title: 'STUDY GROUPS',
                    description: 'meetings of participants between seminars',
                    icon: image4,
                },
                {
                    title: 'LEARNING MATERIALS',
                    description: 'workbook for each seminar',
                    icon: image5,
                },
                {
                    title: 'CERTIFICATE',
                    description: 'issued for each completed seminar',
                    icon: image6,
                },
                {
                    title: 'DIPLOMA',
                    description: 'issued for completion of all seminars',
                    icon: image7,
                },
            ]  
        }, 
        ro: {
            features: [
                {
                    titlu: "COACHES",
                    description: "lideri de afaceri experimentați și de succes din SUA",
                    icon: image1,
                },
                {
                    title: "PUBLIC ȚINTĂ",
                    description: "proprietari de afaceri și executivi",
                    icon: image2,
                },
                {
                    title: "VISITE LA COMPANII",
                    description: "consultații individuale în compania dumneavoastră de către formatorii cursului",
                    icon: image3,
                },
                {
                    title: "STUDY GROUPS",
                    description: "întâlniri ale participanților între seminarii",
                    icon: image4,
                },
                {
                    title: "MATERIALE DE ÎNVĂȚARE",
                    description: "caiet de lucru pentru fiecare seminar",
                    icon: image5,
                },
                {
                    title: "CERTIFICAT",
                    description: "eliberat pentru fiecare seminar promovat",
                    icon: image6,
                },
                {
                    title: "DIPLOMĂ",
                    description: "eliberat pentru toate seminariile absolvite",
                    icon: image7,
                },
            ]  
        }, 
        ru: {
            features: [
                {
                    title: 'ТРЕНЕРЫ',
                    description: 'опытные и успешные бизнес-лидеры из США',
                    icon: image1,
                },
                {
                    title: 'ЦЕЛЕВАЯ АУДИТОРИЯ',
                    description: 'владельцы бизнеса и руководители предприятий',
                    icon: image2,
                },
                {
                    title: 'ПОСЕЩЕНИЕ КОМПАНИИ',
                    description: 'индивидуальное консультирование в вашей компании тренерами курса',
                    icon: image3,
                },
                {
                    title: 'УЧЕБНЫЕ ГРУППЫ',
                    description: 'встречи участников между семинарами',
                    icon: image4,
                },
                {
                    title: 'УЧЕБНЫЕ МАТЕРИАЛЫ',
                    description: 'рабочая тетрадь на каждый семинар',
                    icon: image5,
                },
                {
                    title: 'СЕРТИФИКАТ',
                    description: 'выдается за каждый пройденный семинар',
                    icon: image6,
                },
                {
                    title: 'ДИПЛОМ',
                    description: 'выдается за прохождение всех семинаров',
                    icon: image7,
                },
            ]  
        }
    }

    return (
        <div id="course-format" className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 bg-indigo-900 py-8 px-4">
            {content[language].features.map((feature, index) => (
                <div key={index} className={"flex flex-col items-center text-white p-6 rounded-lg" + (index % 2 === 0 ^ index > 3 ? " bg-indigo-700" : " bg-indigo-800")}>
                    <img src={feature.icon} alt={feature.title} className="w-12 h-12 mb-4" />
                    <h3 className="text-lg font-bold mb-2">{feature.title}</h3>
                    <p className="text-sm text-center">{feature.description}</p>
                </div>
            ))}
        </div>
    );
};

export default FeatureGrid;
