import React, { useContext, useState } from 'react';
import { LanguageContext } from '../../store/LanguageContext'; // Adjust the import path

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { language, toggleLanguage } = useContext(LanguageContext);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      setIsOpen(false);
    }
  };

    const content = {
        en: {
            button1: "Course description",
            button2: "Course format",
            button3: "Trainers",
            button4: "Seminar schedule",
            button5: "Registration steps",
        }, 
        ro: {
            button1: "Descrierea cursului",
            button2: "Formatul cursului",
            button3: "Speakeri",
            button4: "Orarul seminarelor",
            button5: "Procesul de inregistrare",
        }, 
        ru: {
            button1: "Описание курса",
            button2: "Формат курса",
            button3: "Тренеры",
            button4: "Расписание семинаров",
            button5: "Этапы регистрации",
        }
    }

  return (
    <div className="fixed top-4 right-4 z-50 flex items-center space-x-4">
      {/* Language Switcher Button */}
      <button
        onClick={toggleLanguage}
        className="text-white bg-gray-800 p-2 rounded-md"
      >
        {language === 'en' && 'EN'}
        {language === 'ru' && 'RU'}
        {language === 'ro' && 'RO'}
      </button>
            {/* Hamburger Icon */}
            <button
                onClick={toggleMenu}
                className="text-white bg-red-800 p-2 rounded-md"
            >
                {isOpen ? (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                        </svg>
                    )}
            </button>
            {/* Menu Overlay */}
            {isOpen && (
                <div className="fixed inset-y-0 right-0 w-1/3 bg-red-800 bg-opacity-95 z-40 flex flex-col items-start p-8">
                    <button onClick={toggleMenu} className="ml-auto mb-8">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                    <nav className="space-y-4">
                        <button onClick={() => scrollToSection('course-info')} className="text-white block text-lg font-semibold">{content[language].button1}</button>
                        <button onClick={() => scrollToSection('course-format')} className="text-white block text-lg font-semibold">{content[language].button2}</button>
                        <button onClick={() => scrollToSection('trainers')} className="text-white block text-lg font-semibold">{content[language].button3}</button>
                        <button onClick={() => scrollToSection('schedule')} className="text-white block text-lg font-semibold">{content[language].button4}</button>
                        <button onClick={() => scrollToSection('registration')} className="text-white block text-lg font-semibold">{content[language].button5}</button>
                    </nav>
                </div>
            )}
        </div>
    );
};

export default HamburgerMenu;
