import React, { useContext } from 'react';
import { LanguageContext } from '../../store/LanguageContext';

const Seminars = () => {
    const { language } = useContext(LanguageContext);

    const content = {
        en: {
            seminars: [
                {
                    title: 'Seminar 1',
                    subtitle: 'PRINCIPLES OF EFFECTIVE MARKETING',
                    points: [
                        'Understanding the business',
                        'Identifying a need: the customer',
                        'Satisfying the need: the offer',
                        'Positioning the offer',
                        'Developing a plan',
                    ],
                    bgImage: 'https://sbp.com.ua/wp-content/uploads/2017/09/seminar1.png',
                },
                {
                    title: 'Seminar 2',
                    subtitle: 'PRINCIPLES OF EFFECTIVE FINANCIAL MANAGEMENT OF ENTERPRISE',
                    points: [
                        'Financial reporting and cost analysis',
                        'Budgeting and performance evaluation',
                        'Making decisions about investments',
                        'Management of financial resources',
                        'Risk and information management',
                    ],
                    bgImage: 'https://sbp.com.ua/wp-content/uploads/2017/09/seminar2.png'
                },
                {
                    title: 'Seminar 3',
                    subtitle: 'PRINCIPLES OF EFFECTIVE PERSONNEL MANAGEMENT',
                    points: [
                        'Leadership and organizational structure',
                        'Corporate culture and recruitment',
                        'Motivating employees',
                        'Managing staff performance',
                        'Remuneration system',
                    ],
                    bgImage: 'https://sbp.com.ua/wp-content/uploads/2017/09/seminar3.png',
                },
                {
                    title: 'SEMINAR 4',
                    subtitle: 'STRATEGIC LEADERSHIP',
                    points: [
                        'Vision and purpose',
                        'External environment',
                        'Assessing resources',
                        'Selecting strategic opportunities',
                        'Implementing and managing change',
                    ],
                    bgImage: 'https://sbp.com.ua/wp-content/uploads/2017/09/seminar4.png',
                },
            ]            
        }, 
        ro: {
            seminars: [
                {
                    title: "SEMINAR 1",
                    subtitle: "PRINCIPIILE MARKETINGULUI EFICIENT",
                    points: [
                        'Înțelegerea afacerii',
                        'Identificarea unei nevoi: clientul',
                        'Satisfacerea nevoii: oferta',
                        'Poziționarea ofertei',
                        'Elaborarea unui plan',
                    ],
                    bgImage: 'https://sbp.com.ua/wp-content/uploads/2017/09/seminar1.png',
                },
                {
                    title: "SEMINAR 2",
                    subtitle: "PRINCIPIILE MANAGEMENTULUI EFICIENT AL FINANȚELOR ÎNTREPRINDERILOR",
                    points: [
                        'Raportare financiară și analiza costurilor',
                        'Elaborarea bugetului și evaluarea performanței',
                        'Luarea deciziilor privind investițiile',
                        'Gestionarea resurselor financiare',
                        'Gestionarea riscurilor și a informațiilor',
                    ],
                    bgImage: 'https://sbp.com.ua/wp-content/uploads/2017/09/seminar2.png',
                },
                {
                    title: "SEMINAR 3",
                    subtitle: "PRINCIPIILE MANAGEMENTULUI EFICIENT AL PERSONALULUI",
                    points: [
                        'Leadership și structură organizațională',
                        'Cultura corporativă și recrutarea',
                        'Motivarea angajaților',
                        'Gestionarea performanței personalului',
                        'Sistemul de remunerare',
                    ],
                    bgImage: 'https://sbp.com.ua/wp-content/uploads/2017/09/seminar3.png',
                },
                {
                    title: "SEMINAR 4",
                    subtitle: "STRATEGIC LEADERSHIP",
                    points: [
                        'Viziune și scop',
                        'Mediul extern',
                        'Evaluarea resurselor',
                        'Selectarea oportunităților strategice',
                        'Implementarea și gestionarea schimbării',
                    ],
                    bgImage: 'https://sbp.com.ua/wp-content/uploads/2017/09/seminar4.png',
                },
            ]            
        }, 
        ru: {
            seminars: [
                {
                    title: 'СЕМИНАР 1',
                    subtitle: 'ПРИНЦИПЫ ЭФФЕКТИВНОГО МАРКЕТИНГА',
                    points: [
                        'Понимание бизнеса.',
                        'Выявление потребности: клиент.',
                        'Удовлетворение потребности: предложение.',
                        'Позиционирование предложения.',
                        'Разработка плана.',
                    ],
                    bgImage: 'https://sbp.com.ua/wp-content/uploads/2017/09/seminar1.png',
                },
                {
                    title: 'СЕМИНАР 2',
                    subtitle: 'ПРИНЦИПЫ ЭФФЕКТИВНОГО УПРАВЛЕНИЯ ФИНАНСАМИ ПРЕДПРИЯТИЯ',
                    points: [
                        'Финансовая отчетность и анализ затрат.',
                        'Бюджетирование и оценка результатов деятельности.',
                        'Принятие решений о капиталовложениях',
                        'Управление финансовыми ресурсами.',
                        'Управление рисками и информацией.',
                    ],
                    bgImage: 'https://sbp.com.ua/wp-content/uploads/2017/09/seminar2.png',
                },
                {
                    title: 'СЕМИНАР 3',
                    subtitle: 'ПРИНЦИПЫ ЭФФЕКТИВНОГО УПРАВЛЕНИЯ ПЕРСОНАЛОМ',
                    points: [
                        'Лидерство и организационная структура.',
                        'Корпоративная культура и набор кадров.',
                        'Мотивирование сотрудников.',
                        'Управление деятельностью персонала.',
                        'Система оплаты труда.',
                    ],
                    bgImage: 'https://sbp.com.ua/wp-content/uploads/2017/09/seminar3.png',
                },
                {
                    title: 'СЕМИНАР 4',
                    subtitle: 'СТРАТЕГИЧЕСКОЕ ЛИДЕРСТВО',
                    points: [
                        'Видение и цель.',
                        'Внешнее окружение.',
                        'Оценка ресурсов.',
                        'Выбор стратегических возможностей.',
                        'Реализация и управление изменениями.',
                    ],
                    bgImage: 'https://sbp.com.ua/wp-content/uploads/2017/09/seminar4.png',
                },
            ]            
        }
    }

    return (
        <div id="schedule" className="w-full bg-white py-16 px-4">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                {content[language].seminars.map((seminar, index) => (
                    <div
                        key={index}
                        className="relative p-8 text-white"
                        style={{
                            backgroundImage: `url(${seminar.bgImage})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    >
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <div className="relative z-10">
              <h3 className="text-lg font-bold uppercase">{seminar.title}</h3>
              <h4 className="mt-2 text-xl font-bold">{seminar.subtitle}</h4>
              <ul className="mt-4 space-y-2">
                {seminar.points.map((point, idx) => (
                  <li key={idx} className="flex items-start">
                    <span className="text-red-600 font-bold mr-2">{idx + 1}.</span>
                    <p>{point}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Seminars;
