import React, { createContext, useState } from 'react';

// Create a context for language
export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en'); // Default language

  const toggleLanguage = () => {
    setLanguage((prevLanguage) => {
      if (prevLanguage === 'en') return 'ru';
      if (prevLanguage === 'ru') return 'ro';
      return 'en';
    });
  };

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
