import React, { useState, useContext } from 'react';
import { LanguageContext } from '../../store/LanguageContext';
import { collection, addDoc } from 'firebase/firestore';
import db from '../../firebaseConfig';

const ApplicationForm = () => {

    const { language } = useContext(LanguageContext);
    const [isFormVisible, setFormVisible] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const toggleForm = () => {
        setFormVisible(!isFormVisible);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsSubmitting(true);

        try {
            await addDoc(collection(db, 'applications'), {
                name,
                email,
                phone,
                submittedAt: new Date()
            });

            alert('Application submitted successfully!');
            setFormVisible(false); // Close the form after submission

            // Reset form fields
            setName('');
            setEmail('');
            setPhone('');
        } catch (error) {
            console.error('Error adding document: ', error);
            alert('There was an error submitting your application. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };
    const content = {
        en: {
            name: "Your name:", 
            email: "Your email:", 
            phone: "Your phone number:", 
            send: "Submit",
            button: "Submit an application",             
        }, 
        ro: {
            name: "Numele:", 
            email: "Email-ul:", 
            phone: "Numarul de telefon:", 
            send: "Trimite",
            button: "Trimiteți o cerere",             
        }, 
        ru: {
            name: "Ваше имя:", 
            email: "Ваш емайл:", 
            phone: "Ваш номер телефона:", 
            send: "Отправить",
            button: "Подать заявку",             
        }
    }

    return (
        <div>
            {/* Submit Application Button */}
            <button
                onClick={toggleForm}
                className="bottom-10 mb-8 bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-6 rounded-full"
            >
                {content[language].button}
            </button>

            {/* Form Modal */}
            {isFormVisible && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 text-black">
                    <div className="bg-red-800 p-6 rounded-lg w-80 relative">
                        {/* Close Button */}
                        <button
                            onClick={toggleForm}
                            className="absolute top-2 right-2 text-white"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>

                        <h2 className="text-white text-center font-bold mb-4">{content[language].button}</h2>

                        {/* Form Fields */}
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder={content[language].name}
                                    className="w-full p-2 rounded border border-gray-300"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder={content[language].email}
                                    className="w-full p-2 rounded border border-gray-300"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <input
                                    type="text"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    placeholder={content[language].phone}
                                    className="w-full p-2 rounded border border-gray-300"
                                    required
                                />
                            </div>

                            {/* Submit Button */}
                            <button
                                type="submit"
                                className={`w-full bg-red-600 text-white py-2 rounded ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? 'Submitting...' : 'Submit'}
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ApplicationForm;
