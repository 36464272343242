import React from 'react';
import danMinorPng from '../../assets/dan_minor.png';
import billBarrPng from '../../assets/bill_barr.png';
import fritzSmithPng from '../../assets/fritz_smith.png'
import johnCarterPng from '../../assets/john_carter.png'


const TeamGrid = () => {

    const teamMembers = [{ 
            name: 'Dan Minor', 
            img: danMinorPng,
            features: [
            'Experience in Sales, Engineering, Marketing',
            'Experience in Sales Leadership',
            '6 marketing seminars presented in West Africa',
            'Over 9 years of experience in B2B, in a global manufacturing company',
        ]
        },
        {
            name: 'Bill Barr', 
            img: billBarrPng,
            features: [
            'Vice president of Marketing',
            'Vice president of sales for a large regional bank',
            'Over 20 years of experience in business performance consultancy in the technology industry',
        ]
        },
        {
            name: 'Fritz Smith', 
            img: fritzSmithPng,
            features: [
            'President at Bosmith Furniture Of Bishopville, Inc.',
            'Experience in online marketing and social media marketing',
            'Over 30 years of experience in retail furniture business',
        ]
        },
        {
            name: 'John Carter', 
            img: johnCarterPng,
            features: [
            'Experience in advertising, human resources, real estate',
            'Over 20 years of experience in business and management',
        ]
        },
  ];

  return (
    <div id="trainers" className="w-full bg-white py-16 px-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {teamMembers.map((member, index) => (
          <div key={index} className="flex flex-col items-center bg-gray-100 p-4 rounded-lg shadow-lg">
            <div className="w-24 h-24 md:w-32 md:h-32 rounded-full overflow-hidden shadow-lg mb-4">
              <img src={member.img} alt={member.name} className="w-full h-full object-cover" />
            </div>
            <h3 className="text-lg font-bold mb-2">{member.name}</h3>
            <ul className="text-sm list-disc list-inside text-gray-700">
              {member.features.map((feature, idx) => (
                <li key={idx}>{feature}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamGrid;
