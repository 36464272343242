// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCmfLU9YZhFZhE1AqX60rNyr8btKivitII",
  authDomain: "highmark-3d241.firebaseapp.com",
  projectId: "highmark-3d241",
  storageBucket: "highmark-3d241.appspot.com",
  messagingSenderId: "828004597403",
  appId: "1:828004597403:web:a8eb83a9df94d69b44d03e",
  measurementId: "G-J38R44S1RQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firestore
const db = getFirestore(app);

export default db;
