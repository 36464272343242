import React from 'react';
import logo from '../../assets/logo2.png';

const Footer = () => {

  return (
    <footer className="w-full bg-red-900 text-white py-8">
      <div className="border-t border-red-800 mt-8 pt-4">
        <div className="max-w-7xl mx-auto flex flex-col md:flex-row justify-between items-center px-4 text-xs">
          {/* Bottom Text */}

          {/* Logo */}
          <div className="mt-4 md:mt-0">
            <img src="https://sbp.com.ua/wp-content/uploads/2017/09/Шар-2.png" alt="HighMark, Inc." className="h-8" />
          </div>

          <div className="mt-4 md:mt-0">
            <img src={logo} alt="HighMark, Inc." className="h-32" />
          </div>
          {/* Creator Info */}
                    <div>
                        <p>Business Course: Successful Entrepreneur © 2024</p>
                        <p>  </p>
                        <p className="mt-4 md:mt-0">Created by Dan Tkach</p>
                    </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
