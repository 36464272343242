import React, { useContext } from 'react';
import { LanguageContext } from '../../store/LanguageContext';
import ApplicationForm from './ApplicationForm';
import head from '../../assets/head.png'

function Header() {
    const { language } = useContext(LanguageContext);

    const content = {
        en: {
            text1: "Business Course",             
            text2: "Successful Entrepreneur",             
            button: "Submit an application",             
        }, 
        ro: {
            text1: "Business Course",             
            text2: "Antreprenor de succes",             
            button: "Trimiteți o cerere",             
        }, 
        ru: {
            text1: "Бизнес-курс",             
            text2: "Успешный предприниматель",             
            button: "Подать заявку",             
        }
    }

    return (
        <div className="relative w-full h-4/5 bg-cover bg-center" style={{ backgroundImage: 'url(https://images5.alphacoders.com/349/349314.jpg)' }}>
            {/* Overlay */}
            <div className="absolute inset-0 bg-black opacity-30"></div>

            {/* Content Container */}
            <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white">
                {/* US Map Image */}
                <img src={head} alt="US Map" className="w-1/2 mb-24 mt-10 " />

                {/* Text Content */}
                <div className="absolute w-1/2 max-w-lg transform -translate-y-1/2 text-center mb-16">
                    <h1 className="text-sm sm:text-lg md:text-xl lg:text-2xl font-bold uppercase">{content[language].text1}</h1>
                    <h2 className="mt-2 text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-extrabold">{content[language].text2}</h2>
                </div>

                {/* Button */}
                <ApplicationForm />
            </div>
        </div>
    );
};

export default Header;
