import React from 'react';

const EventLocation = () => {
  return (
    <div className="w-full h-400px flex items-center justify-center my-12">
      <div className="w-full max-w-4xl">
        <h2 className="text-center text-2xl font-bold mb-4">Event Info</h2>
                <p className="text-gray-700 leading-relaxed text-justify">
<a href="#" className="text-blue-600 font-semibold cursor-default pointer-events-none">
Locatia:</a> Chișinău, strada Albișoara  42/1, oficiul 2 (Keystone Events).
</p>

                <p className="text-gray-700 leading-relaxed text-justify">
<a href="#" className="text-blue-600 font-semibold cursor-default pointer-events-none">
Durata Seminarului:</a> 5 zile (28 Octombrie - 1 Noiembrie) de la 09:00 la 13:00.
                    </p>
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2719.327006882503!2d28.841439775940387!3d47.033813727356154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97c4c36c3baab%3A0x87acb4a4addcb98!2sKeystone%20Events!5e0!3m2!1sen!2s!4v1729847277637!5m2!1sen!2s"  
          title="Event Location"
          className="w-full h-96 rounded-lg shadow-lg mt-6"
                    allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  );
};

export default EventLocation;
