import React, { useContext } from 'react';
import { LanguageContext } from '../../store/LanguageContext';
import ApplicationForm from './ApplicationForm';

const RegistrationSteps = () => {
    const { language } = useContext(LanguageContext);

    const content = {
        en: {
            title: "HOW TO REGISTER FOR THE COURSE?",
            steps: [
                {
                    stepNumber: 1,
                    description: 'Apply for the course',
                    icon: 'https://sbp.com.ua/wp-content/uploads/2017/09/01.png',
                },
                {
                    stepNumber: 2,
                    description: 'Get, fill out and send us the form',
                    icon: 'https://sbp.com.ua/wp-content/uploads/2017/09/02.png',
                },
                {
                    stepNumber: 3,
                    description: 'Receive confirmation of enrollment and an invoice from us',
                    icon: 'https://sbp.com.ua/wp-content/uploads/2017/09/03.png'
                },
                {
                    stepNumber: 4,
                    description: 'Pay the cost of the seminar 400 EUR or the entire course 1600 EUR',
                    icon: 'https://sbp.com.ua/wp-content/uploads/2017/09/04.png'
                },
            ]
        }, 
        ro: {
            title: "CUM SĂ VĂ ÎNSCRIEȚI LA CURS?",
            steps: [
                {
                    stepNumber: 1,
                    description: "Înscrie-te la curs",
                    icon: 'https://sbp.com.ua/wp-content/uploads/2017/09/01.png',
                },
                {
                    stepNumber: 2,
                    description: 'Obțineți, completați și trimiteți-ne formularul',
                    icon: 'https://sbp.com.ua/wp-content/uploads/2017/09/02.png',
                },
                {
                    stepNumber: 3,
                    description: 'Primiți confirmarea înscrierii și o factură de la noi',
                    icon: 'https://sbp.com.ua/wp-content/uploads/2017/09/03.png',
                },
                {
                    stepNumber: 4,
                    description: 'Plătiți costul seminarului 400 EUR sau al întregului curs 1600 EUR',
                    icon: 'https://sbp.com.ua/wp-content/uploads/2017/09/04.png',
                },
            ]
        }, 
        ru: {
            title: "КАК ЗАРЕГИСТРИРОВАТЬСЯ НА КУРС?",
            steps: [
                {
                    stepNumber: 1,
                    description: 'Подать заявку на курс',
                    icon: 'https://sbp.com.ua/wp-content/uploads/2017/09/01.png'
                },
                {
                    stepNumber: 2,
                    description: 'Получить, заполнить и отправить нам анкету.',
                    icon: 'https://sbp.com.ua/wp-content/uploads/2017/09/02.png'
                },
                {
                    stepNumber: 3,
                    description: 'Получить от нас подтверждение о зачислении на курс и счет на оплату.',
                    icon: 'https://sbp.com.ua/wp-content/uploads/2017/09/03.png',
                },
                {
                    stepNumber: 4,
                    description: 'Оплатить стоимость семинара 400 EUR или всего курса 1600 EUR.',
                    icon: 'https://sbp.com.ua/wp-content/uploads/2017/09/04.png',
                },
            ]
        }
    }

  return (
        <div id="registration" className="w-full h-full bg-red-800 py-16 px-4 relative">
            <div className="max-w-5xl mx-auto relative">
                {/* Title */}
                <div className="text-white text-center mb-12">
                    <img src="https://sbp.com.ua/wp-content/uploads/2017/09/00.png" alt="Flag Icon" className="w-10 h-16 mx-auto mb-6" />
                    <h2 className="text-2xl font-bold">{content[language].title}</h2>
                </div>

                {/* Vertical Line */}

                {/* Steps */}
                <div className="relative z-10 grid grid-cols-1 gap-12">
                    {content[language].steps.map((step, index) => (
                        <div key={index} className="flex items-center justify-center relative">
                            <div className="w-1/2 flex items-center">
                                <div className="absolute left-1/5 transform -translate-x-1/2 bg-white w-6 h-6 rounded-full z-10"></div>
                                <div className="absolute left-1/5 transform -translate-x-1/2 h-24 bg-white w-px z-0"></div>
                                <img src={step.icon} alt={`Step ${step.stepNumber} Icon`} className="w-12 h-12 ml-12 mr-4" />
                                <p className="text-white">{step.description}</p>
                            </div>
                        </div>
                    ))}
                    <div className='flex items-center w-full relative'>
                        <div className='w-1/2 flex items-center mx-auto'>
            <ApplicationForm /></div></div>
                </div>
            </div>
        </div>
    );
};

export default RegistrationSteps;
