import React, { useContext } from 'react';
import { LanguageContext } from '../../store/LanguageContext';
import monument from '../../assets/monument2.png'

const CourseDescription = () => {
    const { language } = useContext(LanguageContext);

    const content = {
        en: {
            description:  
            <div className="w-full lg:w-2/3 px-4">
                <p className="text-gray-700 leading-relaxed text-justify">
                    An effective, practical and affordable business course for 
                    <a href="#" className="text-blue-600 font-semibold cursor-default pointer-events-none"> owners and managers of small and medium-sized commercial enterprises.</a>
                </p>
                <p className="mt-4 text-gray-700 leading-relaxed text-justify">
                    The course consists of four interrelated seminars. Each seminar lasts five days. 
                    Training takes place in the morning. In the afternoon, the course trainers visit the course participants at their enterprises to provide business advice.
                </p>
                <br/>
                <p className="mt-4 text-gray-700 leading-relaxed text-justify">
                    The course lasts approximately one year. 
                </p>
                </div>
        }, 
        ro: {
            description: 
            <div className="w-full lg:w-2/3 px-4">
                <p className="text-gray-700 leading-relaxed text-justify">
                    Un curs de afaceri eficient, practic și accesibil pentru 
                    <a href="#" className="text-blue-600 font-semibold cursor-default pointer-events-none"> proprietarii și managerii de întreprinderi comerciale mici și mijlocii.</a>
                </p>
                <p className="mt-4 text-gray-700 leading-relaxed text-justify">
                    Cursul constă din patru seminarii interconectate. Fiecare seminar durează cinci zile. 
                    Instruirea începe dimineața. După-amiaza, trainerii cursului vizitează participanții la curs la întreprinderile lor pentru a oferi consultanță în afaceri.
                </p>
                <br/>
                <p className="mt-4 text-gray-700 leading-relaxed text-justify">
                    Cursul durează aproximativ un an.
                </p>
            </div>
        }, 
        ru: {
            description: 
            <div className="w-full lg:w-2/3 px-4">
                <p className="text-gray-700 leading-relaxed text-justify">
                    Эффективный, практический и доступный бизнес-курс для 
                    <a href="#" className="text-blue-600 font-semibold cursor-default pointer-events-none"> владельцев и руководителей коммерческих предприятий малого и среднего бизнеса.</a>
                </p>
                <p className="mt-4 text-gray-700 leading-relaxed text-justify">
                    Данный курс состоит из четырех взаимосвязанных семинаров. Продолжительность каждого семинара - пять дней. 
                    Обучение проводится в первой половине рабочего дня. Во второй половине дня тренеры курса посещают участников курса на их предприятиях для предоставления бизнес-консультаций.
                </p>
                <br/>
                <p className="mt-4 text-gray-700 leading-relaxed text-justify">
                    Срок обучения составляет приблизительно один год. 
                </p>
            </div>
        }
    }

    return (
        <div id="course-info" className="w-full py-16 bg-white flex flex-col lg:flex-row items-center lg:items-start">
            {/* Image Container */}
            <div className="w-full lg:w-1/3 flex justify-center mb-8 lg:mb-0 px-4">
                <img src={monument} alt="Statue of Liberty" className="w-auto" />
            </div>

            {/* Text Content */}
            {content[language].description}
        </div>
  );
};

export default CourseDescription;
